//@ts-nocheck
import instana from 'lib/instana'
import { addStoreLocaleByCookie } from 'components/GeoLocation/helpers'
const { APP_ENV } = process.env
const isDev = APP_ENV !== 'production'
export const jsonOptions = {
  credentials: 'include',
  headers: {
    'Content-Type': 'application/json',
    // TODO - Not sure needed for QA
    ...(isDev
      ? {
          'QA-TESTING': 'SAATCHI_DEVELOPER',
        }
      : {}),
  },
}
export const getOptions = {
  method: 'GET',
  credentials: 'include',
}
export const postOptions = { ...jsonOptions, method: 'POST' }
export const putOptions = { ...jsonOptions, method: 'PUT' }
export const deleteOptions = { ...jsonOptions, method: 'DELETE' }
export const multipartFormDataOptions = {
  method: 'POST',
  credentials: 'include',
}
export type PromisePayload = {
  success: boolean
  payload: Record<string, any>
  [key: string]: any
}
// API Helper for making sure all Fetch calls handles JSON the same way
export async function parseJSON(
  response: Record<string, any>,
  api: string | null | undefined,
  defaultResponse: Record<string, any> | null | undefined
): Promise<Record<string, any>> {
  try {
    const json = await response.json()
    return json
  } catch (err) {
    instana.captureError(err)
    console.error(`ParseJSON ( via APIs) Error ${api || 'API:Unknown'}:`, err)
    return (
      defaultResponse || {
        success: false,
        payload: {},
      }
    )
  }
}
// Legacy API calls for Form Data
export const createFormData = (data: Record<string, any>): FormData => {
  const formData = new FormData()
  Object.keys(data).map((key) => {
    if (Array.isArray(data[key])) {
      return data[key].map((val) => formData.append(`${key}[]`, val))
    }

    return formData.append(key, data[key])
  })
  return formData
}
// This is used for FAILING apis, Redirect users to authentication
export const validateLoginStatus = (response: Record<string, any>): boolean => {
  if (response.loginRequired) {
    // Previously used encodeURIComponent, but double-escaped `&` characters
    // were not processing properly in the auth app and redirected to the homepage.
    const location = addStoreLocaleByCookie(
      `/authentication?redirect_url=${encodeURI(window.location)}`
    )
    window.location = location
    return false
  }

  return true
}
// We want to check if a session has ended, we will read the HEADERS if the `Authenticated-User` from Node Server
// Values are case sensitive and if you loop you will see values are LOWERCASE
export const checkAuthenticatedUserHeaders = (response: Record<string, any>): boolean => {
  const { headers } = response

  if (headers && headers.get('authenticated-user') === 'true') {
    return true
  }

  return false
}

export const visiiProxyOptions = {
  method: 'GET',
  headers: {
    'Content-Type': 'application/json',
    // Add X-Request-Source only for server calls
    ...(typeof window === 'undefined' ? { 'X-Request-Source': 'Saatchi' } : {}),
  },
}
