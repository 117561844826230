import styled from 'styled-components'
const StyleBase = styled.div`
  color: ${(props) => props.theme.colors.default};

  /* Padding for mobile navigation */
  padding-top: ${(props) => (props.isIOSWebview ? '0' : '56')}px;

  /* Zero out top padding for pages that have no promo bar */
  body[data-promo='hidden'] & {
    padding-top: 0;
  }

  @media (min-width: ${(props) => props.theme.breakpoints[1]}) {
    padding: 0;
  }
`
export default StyleBase