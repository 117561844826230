import React from 'react'
import type { ConnectorProps as FlashMessagesConnectorProps } from 'containers/FlashMessage'
import FlashMessagesConnector from 'containers/FlashMessage'
import type { ConnectorProps as PromoBarConnectorProps } from 'connectors/PromoBar'
import PromoBarConnector from 'connectors/PromoBar'
import { PromoWrapper } from './styles'
import { getCurrentPromoCampaignData } from './helpers'
import PromoCampaign from './PromoCampaign'
import PromoCta from './PromoCta'
type Props = FlashMessagesConnectorProps & PromoBarConnectorProps

const Component = (props: Props): React.ReactElement<React.ComponentProps<any>, any> => {
  const { addMessage, promoCampaigns, localeCountryCode, userRole, promoCta, pageType } = props
  const currentPromo = promoCampaigns
    ? getCurrentPromoCampaignData({
        localeCountryCode,
        promoCampaigns,
      })
    : ''
  const currentCTA = promoCta ? promoCta[userRole] || promoCta.guest : ''
  const isCheckoutPage = pageType === '/checkout'
  const shouldHidePromoBar =
    !currentPromo ||
    !currentPromo.tiers ||
    !currentPromo.showToNonAuthenticatedUser === '1' ||
    (currentPromo.showToNonAuthenticatedUser === '0' && userRole === 'guest')
  return (
    <PromoWrapper // TODO: fix nested ternary
       
      {...(isCheckoutPage
        ? shouldHidePromoBar
          ? {
              'data-promo-hide': 'active',
            }
          : {}
        : {})} // data-promo-hide={isCheckoutPage && !shouldHidePromoBar}
      id='saatchi_promo_nano_bar'
    >
      {!currentPromo || !currentPromo.tiers ? (
        <>{!isCheckoutPage ? <PromoCta currentCTA={currentCTA} /> : ''}</>
      ) : (
        <>
          {(currentPromo.showToNonAuthenticatedUser === '0' && userRole !== 'guest') ||
          currentPromo.showToNonAuthenticatedUser === '1' ? (
            <PromoCampaign
              addMessage={addMessage}
              currentPromo={currentPromo}
              localeCountryCode={localeCountryCode}
            />
          ) : (
            <>{!isCheckoutPage ? <PromoCta currentCTA={currentCTA} /> : ''}</>
          )}
        </>
      )}
    </PromoWrapper>
  )
}

export default PromoBarConnector(FlashMessagesConnector(Component))