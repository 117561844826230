//@ts-nocheck
import React from 'react'
import { URLParse } from 'components/GeoLocation/helpers'
import type { ConnectorProps as GeoLocationProps } from 'connectors/GeoLocation'
import GeoLocationConnector from 'connectors/GeoLocation'
type Props = GeoLocationProps & {
  children: React.ReactElement<React.ComponentProps<any>, any>
  [key: string]: string | ((...args: Array<any>) => any)
}

const Component = (props: Props): React.ReactElement<React.ComponentProps<any>, any> => {
  const { children, geoLocation, href: url } = props
  const { storeLocale } = geoLocation
  // TODO - GEO PRICE - remove ternary -> const link =  URLParse(url, storeLocale)
  let link = url

  // Some Components use A but don't have a link, skip this LOGIC
  if (link) {
    const data = URLParse(url, storeLocale)
    const { href } = data
    link = href
  }

  // Copy/Clone All Props
  const anchorProps = { ...props }
  // Delete Attributes we DO NOT NEED
  delete anchorProps.children
  delete anchorProps.geoLocation
  delete anchorProps.geoLocationMetaData
  // Update HREF - Leave Log if we need to check Server Side
  // console.log({ link, hasFeatureFlagsGeoPricesForAustralia })
  anchorProps.href = link
  return <a {...{ ...anchorProps }}>{children}</a>
}

export default GeoLocationConnector(Component)