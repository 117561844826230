import React from 'react' // eslint-disable-line

import styled from 'styled-components'
export const PromoWrapper = styled.div`
  width: 100%;
  height: 60px;
  &[data-promo-hide] {
    margin-top: -56px;
  }
  @media (max-width: 991px) {
    height: 0;
    &[data-promo-hide] {
      margin-top: -56px;
    }
  }
`
export const Promobar = styled.div`
  width: 100%;
  height: 60px;
  @media (max-width: 991px) {
    height: 0;
  }
  /* ANIMATIONS */
  @-webkit-keyframes FadeInNanoBar {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  @-moz-keyframes FadeInNanoBar {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  @-o-keyframes FadeInNanoBar {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  @keyframes FadeInNanoBar {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  [data-style='paragraph-wp'] {
    margin: 0; // in wordpress pages needs to override margin of p
  }

  /* PROMO NANO BAR */
  .wrapper {
    box-sizing: border-box;
    background: ${(props) => (props.background ? `#${props.background}` : '#ffffff')};
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 60px;
    /* ANIMATIONS - Fade In */
    /* -webkit-animation: FadeInNanoBar 1s;
    -moz-animation: FadeInNanoBar 1s;
    -o-animation FadeInNanoBar 1s;
    animation: FadeInNanoBar 1s;
    */
  }

  .wrapper {
    * {
      display: flex;
      box-sizing: border-box;
    }

    .layout {
      flex-direction: row;
      align-items: center;
    }
    .tagline {
      flex: 0 1 auto;
    }
    [data-section='coupon-list'] {
      flex-direction: row;
    }
    .link {
      a {
        color: #ffffff;
        text-decoration: underline;
        margin-left: 30px;
      }
    }

    @media (max-width: 991px) {
      .tagline {
        margin-bottom: 10px;
      }
      [data-section='coupon-list'] {
        width: 100%;
        flex-direction: column;
      }
      [data-type='item'] + [data-type='item'] {
        margin-top: 10px;
      }
    }
  }

  [data-type='countdown'] {
    margin-left: 10px;

    [data-type='hours']:after,
    [data-type='minutes']:after {
      content: ':';
      margin: 0 5px;
    }
  }

  /* MOBILE Layout */
  .wrapper .mobile {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
  }
  .wrapper .mobile .offers-toggle {
    cursor: pointer;
  }
  .wrapper .mobile .offers-toggle .caret {
    background: url('https://d3t95n9c6zzriw.cloudfront.net/static/svg/expand.svg') center center
      no-repeat;
    width: 12px;
    height: 12px;
    margin-left: 5px;
    cursor: pointer;
    transition: transform 0.3s;
    transform: rotate(180deg);
    opacity: 1;
    /* global CSS overwriting opacity .hide */
  }
  .wrapper .mobile .offers-toggle .caret.show {
    transform: rotate(0deg);
  } /* MOBILE Styling */
  @media (min-width: 991px) {
    .wrapper .layout {
      display: flex;
      padding: 10px;
    }
    .wrapper .mobile {
      display: none;
    }
  }
  @media (max-width: 991px) {
    //padding-bottom: 55px;
    .wrapper {
      height: 60px;
      max-height: 60px;
      position: relative;
      margin-top: -56px;
      z-index: 1000;
      /* fix for legacy mobile menu high index */
      top: 0;
      left: 0;
      flex-direction: column;
    }
    .wrapper .layout {
      position: absolute;
      top: 100%;
      right: 0;
      z-index: 10;
      flex-direction: column;
      align-items: flex-start;
      background: #ffffff;
      color: #333333;
      padding: 20px;
      width: 100%;
      max-width: 600px;
      min-height: 100px;
      opacity: 0;
      transition: opacity 0.25s ease-in-out;
      -moz-transition: opacity 0.25s ease-in-out;
      -webkit-transition: opacity 0.25s ease-in-out;
      box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.1), 0 15px 15px 0 rgba(0, 0, 0, 0.1);
      pointer-events: none;
    }
    .wrapper .layout.show {
      opacity: 1;
      pointer-events: initial;
    }
    .wrapper .layout.show > * {
      color: #333333;
    }
    .wrapper .mobile {
      width: 100%;
      display: flex;
    }
  } /* BOUNCE X - CSS Targeting */
  .mobile-navigation,
  .mobile-navigation__menu__inner,
  .mobile-navigation__header,
  .checkout__header,
  .mobile-navigation__search-container {
    margin-top: 60px;
  }
  @media (min-width: 991px) {
    .checkout__header {
      margin-top: 0px !important;
    }
  }
  .mobile-navigation__content {
    height: calc(100% - 60px);
  }
  @media screen and (max-width: 991px) {
    form[action='/all'],
    form[action='/all'] + div,
    div[role='button'][data-reactid='15'] {
      margin-top: 60px;
    }
    div[data-reactid='20'] {
      padding-top: 80px;
    }
    div[data-reactid='547'] {
      top: 60px;
      z-index: 2 !important;
    }
    nav:not([data-section='main-navigation']) > div > div:first-child > div:nth-child(2),
    nav > div > form,
    nav > div > form ~ div {
      margin-top: 60px !important;
    }
    .checkout__container {
      margin-top: 120px;
    }
  }
`
export const CouponCode = styled.div`
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  &:before {
    display: flex;
    content: '';
    width: 1px;
    height: 30px;
    background: #ffffff;
    margin: 0 15px;
  }
  [data-type='info'] {
    flex-direction: column;
  }
  [data-type='title'] {
    display: flex;
    margin: 0 20px 3px 0;
  }
  [data-type='code'] {
    display: flex;
    cursor: pointer;
    &:before {
      content: 'Code:';
      font-weight: 400;
      padding: 0 5px 0 0;
      color: #ffffff;
    }
  }

  @media (max-width: 991px) {
    &[data-type='item'] {
      width: 100%;
      border: 1px solid #333333;
      padding: 10px;
      justify-content: space-between;
      &:before {
        display: none;
      }
    }
    [data-type='code']:before {
      color: #333333;
    }
  }

  [data-style='paragraph-wp'] {
    margin: 0; // in wordpress pages needs to override margin of p
  }
`
