"use strict";
//@ts-nocheck
Object.defineProperty(exports, "__esModule", { value: true });
// Instana Client Side Helpers
// https://docs.instana.io/products/website_monitoring/api/
exports.default = {
    captureError: (error, errorInfo) => {
        if (typeof window !== 'undefined' && window.ineum) {
            // Instana Error
            // https://docs.instana.io/products/website_monitoring/api/#react-integration
            console.error('Instana: captureError', {
                error,
                errorInfo,
            });
            window.ineum('reportError', error, errorInfo
                ? {
                    componentStack: errorInfo.componentStack,
                }
                : null);
        }
    },
    // https://docs.instana.io/products/website_monitoring/backendCorrelation/#retrieving-the-backend-trace-id-in-nodejs
    setTraceId: (traceId) => {
        if (typeof window !== 'undefined' && window.ineum) {
            window.ineum('traceId', traceId);
        }
    },
    // https://www.instana.com/docs/website_monitoring/api/#identifying-users
    setUserInfo: (session) => {
        if (typeof window !== 'undefined' && window.ineum) {
            const { userId, userName, userEmail } = session;
            window.ineum('user', userId, userName, userEmail);
        }
    },
};
